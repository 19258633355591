import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Search } from 'lucide-react';
import { useTemplate } from '../../API';
import _ from 'lodash';

const PoseListSidebar = ({ selectedPose, setSelectedPose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debounceSearchTerm, setDebounceSearchTerm] = useState('');
  const {
    data,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useTemplate('your_models', { q: debounceSearchTerm });
  // Create a debounced function using lodash's debounce
  const debouncedSearch = useCallback(
    _.debounce(search => {
      setDebounceSearchTerm(search);
    }, 500),
    []
  );

  // Event handler for input changes
  const handleSearch = event => {
    debouncedSearch(event.target.value);
    setSearchTerm(event.target.value);
  };
  const loadMoreRef = useRef(null);

  // Intersection observer to call fetchNextPage when the button is near viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        root: null, // relative to the viewport
        rootMargin: '30px', // distance from the bottom of the viewport
        threshold: 0.1, // trigger when 10% of the element is visible
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  // if (isLoading) return <div className="flex flex-col h-full flex-1 gap-2 border-r-[1px]">Loading...</div>;
  if (error) return <div>Error loading models</div>;
  console.log(data)
  return (
    <div className="flex flex-col h-full flex-1 gap-2 border-r-[1px] ">
      {/* Search Input */}
      <div className="flex w-full p-2 border-b-[1px] justify-center ">
        <div className="flex w-full border-[1px] p-2 gap-2 rounded-full">
          <input
            className="outline-none flex w-full"
            placeholder="Search Shots"
            value={searchTerm}
            onChange={handleSearch}
          />
          <Search />
        </div>
      </div>
      {isLoading && (
        <div className="flex pl-4 flex-col h-full flex-1 gap-2 ">
          Loading...
        </div>
      )}

      {!isLoading && data && (
        <>
          {/* Model Selection Info */}
          <div className="w-full pl-4 flex border-b-[1px] p-2 text-sm">
            <div className="flex flex-1">
              {selectedPose?.length > 0
                ? `${selectedPose?.length} ${
                    selectedPose?.length === 1 ? 'defined shot' : 'defined shots'
                  } selected`
                : `${data?.pages
                    .map(page => page.templates?.length)} defined shots`}
            </div>
            {selectedPose?.length > 0 && (
              <div
                className="flex text-sm cursor-pointer text-red-600"
                onClick={() => setSelectedPose([])}
              >
                Deselect all
              </div>
            )}
          </div>

          {/* Models List */}
          <div className="w-full pl-2 flex flex-1 flex-col overflow-y-scroll gap-2">
            {data?.pages.map((page, i) => (
              <React.Fragment key={i}>
                {page?.templates
                  ?.filter(model =>
                    model?.name
                      ?.toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  )
                  .map(model => (
                    <div
                      key={model.template_id}
                      className="flex w-full items-center gap-2 px-2"
                    >
                      <input
                        type="checkbox"
                        checked={selectedPose.includes(model.template_id)}
                        onChange={() => {
                          setSelectedPose(prevSelected => {
                            if (prevSelected.includes(model.template_id)) {
                              return prevSelected.filter(
                                id => id !== model.template_id
                              );
                            } else {
                              return [...prevSelected, model.template_id];
                            }
                          });
                        }}
                      />
                      <div className="w-[12%] bg-gray-200 aspect-1 border-[1px] rounded-md overflow-hidden">
                        <img src={model.thumbnailUrl} alt={model.template_id} />
                      </div>
                      {model.name}
                    </div>
                  ))}
              </React.Fragment>
            ))}

            {/* Load More Button */}
            {hasNextPage && (
              <div className="flex justify-center p-2">
                <button
                  ref={loadMoreRef} // Set the ref here
                  className="p-2  border-opacity-0  text-white rounded"
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage ? 'Loading more...' : 'Load More'}
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PoseListSidebar;
