import React, { useState, useEffect,useCallback,useRef } from 'react';
import axios from 'axios';
import { useUser, useAuth } from '@clerk/clerk-react';
import { useNavigate, } from 'react-router-dom';
import Loader from '../assets/icons/loader.svg';
import { toast } from 'react-toastify';
import useCredits from '../hooks/useCredits';
import {Search} from 'lucide-react';
import { useModels } from '../API';
import _ from 'lodash';
const SubHeader = ({ activeTab, setActiveTab,handleSearch,searchTerm }) => {
  const tabStyle = tab => `
    px-4 py-2 mx-2 rounded-md transition-colors duration-200
    ${
      activeTab === tab
        ? 'text-black font-semibold'
        : 'text-gray-500 hover:text-gray-700'
    }
  `;
  
  return (
    <div className="flex relative justify-between items-center py-2 px-6 border-b border-gray-200">
      <div className="flex ">
        {/* Placeholder for logo or other left-aligned content */}
        
      </div>

      <div className="flex justify-center flex-grow">
        <button
          className={tabStyle('your_models')}
          onClick={() => setActiveTab('your_models')}
        >
          Your Models
        </button>
        <button
          className={tabStyle('foosh_models')}
          onClick={() => setActiveTab('foosh_models')}
        >
          Foosh Models
        </button>
      </div>
      <div className="flex absolute right-2 w-[20%] border-[1px] p-2 gap-2 rounded-full">
          <input
            className="outline-none flex w-full"
            placeholder="Search Products"
            value={searchTerm}
            onChange={handleSearch}
          />
          <Search />
        </div>
    </div>
  );
};

const ModelTrainingModal = ({ navigate }) => {
  const { credits } = useCredits();

  const handleAddModel = () => {
    if (credits.current >= 800) {
      navigate(`/model-images`);
    } else {
      const toastId = toast.error(
        <div className="flex justify-between items-center w-full">
          <span className="flex-grow">Insufficient credits for this task</span>
          <button
            onClick={() => {
              toast.dismiss(toastId);
              navigate('/billing');
            }}
            className="ml-4 px-4 py-2 bg-black hover:bg-gray-800 text-white rounded"
          >
            Add credits
          </button>
        </div>,
        {
          position: 'bottom-center',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          className: 'w-full max-w-md',
        }
      );
    }
  };
  return (
    <div className=" bg-white rounded-xl col-span-2 justify-between flex flex-col w-full aspect-[2/1] p-6 border-[1px] border-[rgba(0,0,0,0.2)] ">
      <div className="grid grid-cols-2  justify-center items-center  gap-2">
        <div className="w-full h-full grid grid-cols-1  gap-2 ">
          <div className=" flex items-end leading-normal  h-full  text-wrap   ">
            <p className="text-[18px] font-[600] tracking-wider">
              Train Your Model Photos <br /> to generate their <br />{' '}
              photoshoots in studio
            </p>
          </div>
          <div className="grid grid-cols-3 w-full gap-1 overflow-clip ">
            <div className="w-full  rounded-md bg-gray-200 ">
              <img
                className="w-full h-full object-cover "
                src="https://d2h4y8xaq7mn9l.cloudfront.net/training-modal-small-1.png"
                alt="img-1"
              />
            </div>
            <div className="w-full  rounded-md bg-gray-200 ">
              {' '}
              <img
                className="w-full h-full object-cover "
                src="https://d2h4y8xaq7mn9l.cloudfront.net/training-modal-small-2.png"
                alt="img-2"
              />
            </div>
            <div className="w-full  rounded-md bg-gray-200 ">
              {' '}
              <img
                className="w-full h-full object-cover "
                src="https://d2h4y8xaq7mn9l.cloudfront.net/training-modal-small-3.png"
                alt="img-1"
              />
            </div>
          </div>
        </div>
        <div className="w-full h-full  grid grid-cols-3  gap-1   bg-yello-200  ">
          <div className="col-span-2 rounded-md bg-gray-200">
            <img
              className="w-full h-full object-cover "
              src="https://d2h4y8xaq7mn9l.cloudfront.net/training-modal-big-1.png"
              alt="img-2"
            />
          </div>
          <div className="  rounded-md bg-gray-200">
            <img
              className="w-full  h-full object-cover"
              src="https://d2h4y8xaq7mn9l.cloudfront.net/training-modal-big-2.png"
              alt="img-2"
            />
          </div>
        </div>
      </div>
      <div
        className=" bg-black p-4 text-white text-center text-[18px] font-bold rounded-md cursor-pointer"
        onClick={handleAddModel}
      >
        Add your model on FOOSH
      </div>
    </div>
  );
};

const UserModel = ({ model }) => {
  const navigate = useNavigate();
  return (
    <div className="w-full overflow-hidden relative aspect-1 rounded-xl cursor-pointer"
      onClick={() => {
        localStorage.setItem('fooshModelsSelected', JSON.stringify([model]));
        navigate(`/modelDetails/${model.id}`)
      }}>
      <div className="bg-gradient-to-b relative from-white to-gray-400 rounded-md w-full h-full">
        <img
          src={model.thumbnailUrl}
          className={model.status !== 'SUCCESS' ? 'blur-md ' : ''}
          alt="model-img"
        />
        {model.status !== 'SUCCESS' && (
          <div className="absolute z-10 top-0 left-0 w-full h-full bg-black opacity-70 blur-sm" />
        )}
      </div>
      {model.status !== 'SUCCESS' ? (
        <div className="h-10 text-white bg-opacity-50 p-2 w-full absolute bottom-6 z-10">
          <div>{model.name}</div>
          {model.status !== 'SUCCESS' && (
            <div>
              {model.type === 'person' ? 'Ready in 10mins' : `Ready to Use in ${model.estimated_time_of_completion}`}
            </div>
          )}
        </div>
      ) : (
        <div className="h-12 bg-gradient-to-b from-transparent to-black bg-blur-md text-white px-2 pt-4 w-full absolute -bottom-0 z-10">
          <div>{model.name}</div>
        </div>
      )}
    </div>
  );
};
const ModelContent = ({ activeTab,debounceSearchTerm }) => {
  const [visited, setVisited] = useState(true);
  // const [models, setModels] = useState([]);
  const [stop, setStop] = useState(false);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(false);
  const { user } = useUser();
  const navigate = useNavigate();
  const { getToken } = useAuth();
 const loadMoreRef = useRef(null);
    const {
      data,
      isLoading,
      error,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
    } = useModels(activeTab, { q: debounceSearchTerm,type:"all" });
  // Intersection observer to call fetchNextPage when the button is near viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage &&!isLoading) {
          fetchNextPage();
        }
      },
      {
        root: null, // relative to the viewport
        rootMargin: '30px', // distance from the bottom of the viewport
        threshold: 0.1, // trigger when 10% of the element is visible
      }
    );
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);
  // const fetchData = async () => {
  //   setLoading(true);
  //   setError(false);
  //   try {
  //     const token = await getToken({ template: 'token' });
  //     const endpoint = activeTab === 'your_models' ? 'models' : 'models/public';
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/${endpoint}?type=all`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setModels(response.data.loras);
  //     setVisited(response.data.loras.length > 0);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     setError(true);
  //     setStop(true);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    if(data && !isLoading){
      setVisited(data?.pages[0]?.loras?.length>0)
    }
  }, [data,isLoading]);

  if (isLoading) {
    return (
      <div className="w-full text-xl font-bold flex h-full justify-center items-center">
        <img className="h-20" src={Loader} alt="loader" />
      </div>
    );
  }

  if (error) {
    return <div>Error in the API call...</div>;
  }

  return (
    <div
      className={`grid grid-cols-4 p-4 ${
        !visited ? 'items-center justify-center grid-cols-2 mx-auto' : ''
      }  w-full gap-4`}
    >
      {!visited && (
        <div className="flex col-span-1 w-full aspect-1 rounded-lg bg-gradient-to-b from-gray-200 to-gray-50"></div>
      )}
      {activeTab === 'your_models' && !stop && (
        <ModelTrainingModal navigate={navigate} />
      )}
      {!visited &&
        Array(5)
          .fill()
          .map((_, index) => (
            <div
              key={`empty-${index}`}
              className="bg-gradient-to-b from-gray-200 to-gray-50 rounded-lg w-full aspect-1"
            ></div>
          ))}
      {visited &&
        data?.pages.map((page)=>{
          console.log(page,"here is the page")
          return page.loras.map(model => <UserModel model={model} key={model.id} />)}
        )}
           {/* Load More Button */}
           {hasNextPage && !isLoading &&!isFetchingNextPage&& (
    <div className="flex justify-center p-2">
      <button
        ref={loadMoreRef} // Set the ref here
        className="p-2  border-opacity-0  text-white rounded"
        onClick={() => fetchNextPage()}
        disabled={!hasNextPage || isFetchingNextPage}
      >
        {isFetchingNextPage ? <div className="w-full text-xl font-bold flex h-full justify-center items-center">
        <img className="h-20" src={Loader} alt="loader" />
      </div> : 'Load More'}
      </button>
    </div>
  )}
    </div>
  );
};

const ModelTrainingView = () => {
  const [activeTab, setActiveTab] = useState('your_models');
  const [searchTerm, setSearchTerm] = useState('');
    const [debounceSearchTerm,setDebounceSearchTerm]=useState('');
   // Create a debounced function using lodash's debounce
    const debouncedSearch = useCallback(_.debounce((search) => {
      setDebounceSearchTerm(search);
      // setShowModels(()=>true);
    }, 500), []);
 // Event handler for input changes
 const handleSearch = (event) => {
  debouncedSearch(event.target.value);
  setSearchTerm(event.target.value)
};
  return (
    <div className="w-full h-full overflow-hidden overflow-y-auto flex grow flex-col mx-auto pb-8">
      <SubHeader activeTab={activeTab} setActiveTab={setActiveTab} handleSearch={handleSearch} searchTerm={searchTerm} />
      <ModelContent activeTab={activeTab} debounceSearchTerm={debounceSearchTerm} />
    </div>
  );
};

export default ModelTrainingView;
