import React, { useState, useEffect, useRef } from 'react';
import './InstructionPanel.css';
import { X, Image, Lightbulb, Wand2, ChevronsDown, Plus } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import { useUser, useAuth } from '@clerk/clerk-react';
import { toast } from 'react-toastify';
import select_model_gif from '../assets/icons/select_model.gif';
import ModelSelectionPopup from './ModelSelectionPopup';
import { useImageDetails } from '../API';
import nuxdata from '../constants/NuxData';
import { useNavigate } from 'react-router-dom';
import useTypingAnimation from '../hooks/useTypingAnimation';
import AspectRatioComponent from './InstructionPanel/AspectRatioComponent';

const InstructionPanel = ({
  showNux,
  nuxUser,
  setShowNux,
  onGenerateImage,
  onGenerationStart,
  onGenerationEnd,
  setScrollManger,
  scrollDetect,
  setScrollDetect,
}) => {
  const [isModelPopupOpen, setIsModelPopupOpen] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isEnhancing, setIsEnhancing] = useState(false);
  const imageId = queryParams.get('imageId');
  const normalizeAspectRatio = ratio => {
    const validRatios = ['4:3', '3:4', '1:1', '16:9', '9:16', '2:1'];
    return validRatios.includes(ratio) ? ratio : '1:1';
  };

  const [selectedModels, setSelectedModels] = useState(() => {
    try {
      const savedModels = localStorage.getItem('fooshModelsSelected');
      if (savedModels) {
        return JSON.parse(savedModels);
      }
      return [];
    } catch (error) {
      console.error('Error retrieving model data:', error);
      return [];
    }
  });

  const [promptText, setPromptText] = useState('');

  const [aspectRatio, setAspectRatio] = useState(() => {
    try {
      const savedAspectRatio = localStorage.getItem('aspectRatioFoosh');
      if (savedAspectRatio) {
        return normalizeAspectRatio(savedAspectRatio);
      }

      return '1:1';
    } catch (error) {
      console.error('Error retrieving aspect ratio:', error);
      return '1:1';
    }
  });

  const [isGenerating, setIsGenerating] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { user } = useUser();
  const { getToken } = useAuth();
  const textareaRef = useRef(null);
  const [referenceImage, setReferenceImage] = useState(null);
  const fileInputRef = useRef(null);
  const [isGeneratingCaption, setIsGeneratingCaption] = useState(false);
  const [captionText, setCaptionText] = useState('');
  const animatedCaption = useTypingAnimation(
    captionText,
    isGeneratingCaption,
    100
  );
  const { data, isLoading, error } = useImageDetails(imageId);
  const [nuxselectedModel, setNuxSelectedModel] = useState('Walkr Shoes');
  const [promptIndex, setPromptIndex] = useState(0);
  const [showImages, setShowImages] = useState(false);
  const containerRef = useRef(null);

  // this is to handle the outside wrapper state handling of the caption writing
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        if (document.activeElement === textareaRef.current) {
          textareaRef.current.blur();
        }
        handleBlur();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleNuxModelChange = model => {
    setPromptIndex(0);
    setShowImages(false);
    setNuxSelectedModel(model);
  };
  useEffect(() => {
    const prompt = nuxdata[nuxselectedModel].prompt;
    const interval = setInterval(() => {
      if (promptIndex < prompt.length) {
        setPromptIndex(promptIndex + 1);
      } else {
        setShowImages(true);
        clearInterval(interval);
      }
    }, 1);

    return () => clearInterval(interval);
  }, [nuxdata, nuxselectedModel, promptIndex]);

  const navigate = useNavigate();

  useEffect(() => {
    setPromptIndex(0);
    setShowImages(false);
  }, [showNux]);

  useEffect(() => {
    if (imageId && data && !isLoading && !error) {
      const dataTranfered = data.data.details;
      setSelectedModels(dataTranfered.models_used);
      setAspectRatio(dataTranfered.aspect_ratio);
      setPromptText(dataTranfered.prompt);
      handleFocus();
      const textarea = textareaRef.current;
      textarea.focus();
      // Remove query parameters without reload
      const newUrl = window.location.pathname;
      window.history.replaceState({}, '', newUrl);
    }
  }, [imageId, data, isLoading]);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async e => {
        setReferenceImage(e.target.result);
        await generateCaption(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const generateCaption = async file => {
    setIsGeneratingCaption(true);
    setCaptionText('Generating caption...');

    const formData = new FormData();
    formData.append('image', file);

    try {
      const token = await getToken({ template: 'token' });
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/generate-ref-image-caption?model_id=${selectedModels[0].id}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setCaptionText(data.caption);
      setPromptText(data.caption);
      setIsExpanded(true);
    } catch (error) {
      console.error('Error generating caption:', error);
      toast.error('Failed to generate caption');
      setCaptionText('');
    } finally {
      setIsGeneratingCaption(false);
    }
  };

  const removeReferenceImage = () => {
    setReferenceImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleModelSelection = models => {
    setSelectedModels(models);
    if (models.length > 0) {
      localStorage.setItem('fooshModelsSelected', JSON.stringify(models));
    } else {
      localStorage.removeItem('fooshModelsSelected');
    }
    setIsModelPopupOpen(false);
  };

  const onRemoveModel = modelId => {
    const updatedModels = selectedModels.filter(model => model.id !== modelId);
    setSelectedModels(updatedModels);
    if (updatedModels.length > 0) {
      localStorage.setItem(
        'fooshModelsSelected',
        JSON.stringify(updatedModels)
      );
    } else {
      localStorage.removeItem('fooshModelsSelected');
    }
    if (updatedModels.length === 0) {
      removeReferenceImage();
    }
  };

  useEffect(() => {
    if (isExpanded) {
      adjustTextareaHeight();
    }
  }, [promptText, isExpanded]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleFocus = () => {
    setIsExpanded(true);
    setScrollManger(true);
    adjustTextareaHeight();
  };

  const handleBlur = () => {
    setIsExpanded(false);
    setShowNux(false);
    setScrollManger(false);
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = '';
    }
  };

  useEffect(() => {
    if (scrollDetect) {
      if (document.activeElement === textareaRef.current) {
        textareaRef.current.blur();
      }
      handleBlur();
      setScrollDetect(false);
    }
  }, [scrollDetect, setScrollDetect]);

  const handlePromptChange = e => {
    setPromptText(e.target.value);
  };

  const handleGenerate = async () => {
    if (promptText.trim() === '' || selectedModels.length === 0) {
      toast.error('Please enter a prompt and select a model.');
      return;
    }

    if (!user) {
      toast.error('You must be logged in to generate images.');
      return;
    }
    setShowNux(false);
    setIsGenerating(true);
    onGenerationStart({ promptText, aspectRatio });

    const payload = {
      prompt: promptText,
      models: selectedModels.map(model => model.id || model._id),
      aspectRatio,
    };

    //making prompt box empty after generation clicked
    setPromptText('');

    try {
      const token = await getToken({ template: 'token' });
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/generate`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        if (response.status === 475) {
          const toastId = toast.error(
            <div className="flex justify-between items-center w-full">
              <span className="flex-grow">
                Insufficient credits for this task{' '}
              </span>
              <button
                onClick={() => {
                  toast.dismiss(toastId);
                  navigate('/billing');
                }}
                className="ml-4 px-4 py-2 bg-black hover:bg-gray-800 text-white rounded"
              >
                Add credits
              </button>
            </div>,
            {
              position: 'bottom-center',
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              className: 'w-full max-w-md',
            }
          );
          return;
        }
        throw new Error('Failed to trigger generation. Please try again.');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let generatedImages = 0;
      let currentGenerationId = null;

      const processImage = imageData => {
        const processedImage = {
          ...imageData,
          generation_id: currentGenerationId || imageData.generation_id,
        };
        onGenerateImage({
          image: processedImage,
          aspectRatio,
        });
        generatedImages++;
        console.log(`Processed image ${generatedImages}`);
      };

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n').filter(line => line.trim() !== '');

        for (const line of lines) {
          try {
            const jsonData = JSON.parse(line);

            // Handle errors first
            if (jsonData.error) {
              if (jsonData.error_code === 475) {
                const toastId = toast.error(
                  <div className="flex justify-between items-center w-full">
                    <span className="flex-grow">
                      Insufficient credits for this task{' '}
                    </span>
                    <button
                      onClick={() => {
                        toast.dismiss(toastId);
                        navigate('/billing');
                      }}
                      className="ml-4 px-4 py-2 bg-black hover:bg-gray-800 text-white rounded"
                    >
                      Add credits
                    </button>
                  </div>,
                  {
                    position: 'bottom-center',
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    className: 'w-full max-w-md',
                  }
                );
                return;
              } else {
                toast.error(`Generation failed: ${jsonData.error}`);
                return;
              }
            }

            // Handle regular generation (no status field)
            if (!jsonData.status) {
              processImage(jsonData);
              continue;
            }

            // Handle FAL generation (with status field)
            switch (jsonData.status) {
              case 'started':
                currentGenerationId = jsonData.generation_id;
                console.log('Generation started:', currentGenerationId);
                break;

              case 'processing':
                if (jsonData.image) {
                  processImage(jsonData.image);
                }
                break;

              case 'completed':
                if (jsonData.success) {
                  console.log(
                    `Generation completed with ${generatedImages} images`
                  );
                  if (generatedImages > 0) {
                    toast.success(
                      `${jsonData.total_images} image${
                        jsonData.total_images > 1 ? 's' : ''
                      } generated successfully!`
                    );
                  }
                  return;
                }
                break;

              case 'error':
                toast.error(`Generation failed: ${jsonData.error}`);
                return;
            }
          } catch (error) {
            console.error('Error parsing JSON:', error);
            console.error('Problematic chunk:', line);
            continue; // Skip problematic chunks and continue processing
          }
        }
      }

      // Show success message for regular generation
      if (generatedImages > 0) {
        toast.success(
          `${generatedImages} image${
            generatedImages > 1 ? 's' : ''
          } generated successfully!`
        );
      } else {
        toast.warning('No images were generated.');
      }
    } catch (error) {
      console.error('Error during generation:', error);
      toast.error(`Generation failed: ${error.message}`);
    } finally {
      setIsGenerating(false);
      onGenerationEnd();
    }
  };
  const handleEnhancePrompt = async () => {
    if (!promptText.trim() || selectedModels.length === 0) return;

    setIsEnhancing(true);
    try {
      const token = await getToken({ template: 'token' });
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/enhance-text`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            text: promptText,
            model: selectedModels.map(model => ({
              id: model.id,
              type: model.type,
              name: model.name,
            })),
          }),
        }
      );

      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      setPromptText(data.enhanced);
      toast.success('Prompt enhanced successfully!');
    } catch (error) {
      console.error('Error enhancing prompt:', error);
      toast.error('Failed to enhance prompt');
    } finally {
      setIsEnhancing(false);
    }
  };

  return (
    <div
      ref={containerRef}
      className={`instruction-panel-container flex-col ${
        isExpanded ? 'expanded' : ''
      }`}
    >
      {/* NUX section */}
      {
        <div className={`flex flex-col ${showNux ? 'pb-4' : 'pb-2'}`}>
          <div
            className={`font-bold flex gap-2 tracking-wide ${
              showNux ? 'mb-4' : 'mb-0'
            } cursor-pointer`}
            onClick={() => setShowNux(prev => !prev)}
          >
            <Lightbulb /> How to imagine your shoot
          </div>
          {showNux && (
            <div className="flex gap-1">
              <div className="w-[15%] flex flex-col justify-between gap-[0.6rem]">
                {Object.keys(nuxdata).map(model => (
                  <div
                    key={model}
                    className={`cursor-pointer border-[.5px] ${
                      nuxdata[model].model.name === nuxselectedModel
                        ? 'border-black font-bold'
                        : ''
                    } flex gap-2 items-center p-2 rounded-2xl bg-white`}
                    onClick={() => handleNuxModelChange(model)}
                  >
                    <div className="bg-gray-100 w-8 aspect-1 rounded-lg">
                      <img
                        src={nuxdata[model].model.image}
                        alt={nuxdata[model].model.name}
                        className="object-cover w-full h-full rounded-lg"
                      />
                    </div>
                    <div>{nuxdata[model].model.name}</div>
                  </div>
                ))}
                <div className=" flex flex-1 justify-between items-center">
                  <ChevronsDown />
                  <p className=" font-bold text-[.8rem]">
                    Let's get you started now
                  </p>
                  <ChevronsDown />
                </div>
              </div>
              <div className="flex flex-1 ml-6 gap-4">
                <div className="w-[42%] rounded-3xl bg-white p-4 mr-2">
                  {nuxdata[nuxselectedModel].prompt.slice(0, promptIndex)}
                </div>
                <div className="w-[28%] rounded-3xl p-1 aspect-4/3">
                  <img
                    className={`object-cover w-full h-full rounded-3xl opacity-0 transition-opacity duration-[1000ms] delay-500 ease-linear ${
                      showImages ? 'opacity-100 visible' : 'opacity-0 invisible'
                    }`}
                    src={nuxdata[nuxselectedModel].images[0]}
                    alt={nuxdata[nuxselectedModel].model.name}
                  />
                </div>
                <div className="w-[28%] p-1 rounded-3xl aspect-4/3">
                  <img
                    className={`object-cover w-full h-full rounded-3xl opacity-0 transition-opacity duration-[1000ms] delay-500 ease-linear ${
                      showImages ? 'opacity-100 visible' : 'opacity-0 invisible'
                    }`}
                    src={nuxdata[nuxselectedModel].images[1]}
                    alt={nuxdata[nuxselectedModel].model.name}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      }

      {/* Main instruction panel */}
      <div className="flex w-full gap-4">
        <div className="instruction-panel2 rounded-2xl shadow-[0_3px_10px_rgb(0,0,0,0.2)] bg-white">
          <div className="instruction-panel-top-row p-2">
            <div className="relative flex flex-wrap items-center gap-2">
              {selectedModels.length === 0 ? (
                <div className="flex items-center gap-2">
                  <div
                    className="relative flex mr-2 rounded-lg ani-block cursor-pointer"
                    onClick={() => {
                      setIsModelPopupOpen(!isModelPopupOpen);
                      setShowNux(false);
                    }}
                  >
                    <div
                      className={`select-model-button flex justify-center items-center gap-2 p-[0.45rem] m-[.5px] ${
                        isModelPopupOpen ? 'bg-gray-200' : 'bg-white'
                      } rounded-lg relative z-10`}
                    >
                      <img
                        className="h-7 w-7 rounded-sm"
                        src={select_model_gif}
                        alt="gif"
                      />
                      <div>Select Models</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-wrap items-center gap-2">
                  {selectedModels.length === 1 && (
                    <div
                      className="border-[1px] p-2  rounded-lg"
                      onClick={() => setIsModelPopupOpen(!isModelPopupOpen)}
                    >
                      <Plus />
                    </div>
                  )}
                  {/* Selected Model Tags */}
                  {selectedModels.map(model => (
                    <div key={model.id} className="selected-model-tag">
                      <img
                        src={model.thumbnailUrl}
                        alt={model.name}
                        className="selected-model-thumbnail"
                      />
                      <span>{model.name}</span>
                      <X size={12} onClick={() => onRemoveModel(model.id)} />
                    </div>
                  ))}
                  {/* Aspect Ratio when no models selected */}
                  {/* Aspect Ratio Select */}
                  <AspectRatioComponent
                    aspectRatio={aspectRatio}
                    setAspectRatio={setAspectRatio}
                  />

                  {/* Reference Image Upload */}
                  <div
                    className="add-reference-image flex justify-center items-center gap-2 p-[0.45rem] m-[1.8px] rounded-md  z-10 cursor-pointer"
                    onClick={handleUploadClick}
                  >
                    <Image size={16} />
                    <div>Image to prompt</div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      accept="image/*"
                      style={{ display: 'none' }}
                    />
                  </div>

                  {/* Enhance Prompt - Only show when there's text */}
                  {promptText.trim() !== '' && (
                    <div className="flex mr-2 rounded-lg ani-block-wb cursor-pointer">
                      <div
                        className={`${
                          isEnhancing
                            ? 'm-[1px] pr-12'
                            : 'border-[1px] border-gray-300 '
                        }  bg-white z-10 font-bold text-[14px] flex justify-center items-center gap-2 p-[0.38rem] px-4  rounded-lg cursor-pointer hover:bg-gray-100 `}
                        onClick={handleEnhancePrompt}
                      >
                        {/* <Wand2 size={16} /> */}
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1H12.5"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M1 5H12.5"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M1 9H7"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M11 7L10.3183 8.31835L9 9L10.3183 9.68165L11 11L11.6817 9.68165L13 9L11.6817 8.31835L11 7Z"
                            fill="black"
                          />
                        </svg>

                        <div>
                          {isEnhancing ? 'Enhancing...' : 'Enhance prompt'}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* Model Selection Popup */}
              {isModelPopupOpen && (
                <div className="absolute left-0 top-full mt-2 w-[350px] z-50">
                  <ModelSelectionPopup
                    onSelectModels={handleModelSelection}
                    initialSelectedModels={selectedModels}
                    nuxUser={nuxUser}
                  />
                </div>
              )}
            </div>
          </div>

          {/* Prompt Textarea */}
          <div className="flex ml-2 mb-2 ">
            {referenceImage && (
              <div className="w-36 h-36 relative rounded-3xl shadow-xl">
                <div
                  className="absolute right-0  h-4 w-4 flex justify-center items-center rounded-full m-1 overflow-hidden bg-black text-sm shadow-sm shadow-black font-bold cursor-pointer "
                  onClick={() => removeReferenceImage()}
                >
                  <X className="m-[.12rem] text-white" />
                </div>
                <img
                  src={referenceImage}
                  alt="Reference"
                  className=" w-full h-full object-cover rounded  z-20 "
                />
              </div>
            )}
            <textarea
              ref={textareaRef}
              className={`prompt-textarea focus:outline-none ${
                isExpanded ? 'expanded' : ''
              }`}
              placeholder="Write your prompt here. Tip: Use the model name in the first sentence"
              value={isGeneratingCaption ? animatedCaption : promptText}
              onChange={handlePromptChange}
              onFocus={handleFocus}
              rows={1}
            />
          </div>
        </div>

        {/* Generate Button */}
        <button
          className="generate-button"
          onClick={handleGenerate}
          disabled={
            isGenerating || selectedModels.length === 0 || isGeneratingCaption
          }
        >
          <img src="/frontArrow.svg" alt="front-arrow" />
        </button>
      </div>

      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept="image/*"
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default InstructionPanel;
