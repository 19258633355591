import AllShotsView from '../GalleryView/AllShotsView';

const AllGenerations = ({
  selectedImage,
  setSelectedImage,
  setUpscaleQueue,
  setCompletedUpscale,
  selectedModel,
}) => {
  return (
    <div className="flex flex-1 overflow-hidden">
      <div
        className="flex-1 overflow-y-auto" // Use flex-1 and overflow-y-auto for scrolling
      >
        <AllShotsView
          LkView={true}
          setUpscaleQueue={setUpscaleQueue}
          setCompletedUpscale={setCompletedUpscale}
          setSelectedImage={setSelectedImage}
          selectedModel={selectedModel}
        />
      </div>
    </div>
  );
};
export default AllGenerations;
