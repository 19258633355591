import React, { useCallback } from 'react';
import Loader from '../../assets/icons/loader.svg'
import GeneratedImage from '../../components/GeneratedImage';

const ImageGrid = ({
  activeTab,
  images,
  setSelectedImage,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  setUpscaleQueue,
  setCompletedUpscale,
  LkView
}) => {

  const lastImageRef = useCallback(node => {
    if (node !== null) {
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
          }
        },
        { threshold: 0.1 }
      );
      observer.observe(node);
      return () => observer.disconnect();
    }
  }, [hasNextPage, fetchNextPage]);

  const columns = [[], [], [], []];
  images.forEach((image, index) => {
   LkView ? columns[index % 4].push(image) : columns[index % 4].push(image)
  });

  return (
    <div className="px-4 py-8 w-full">
      <div className="flex flex-row gap-4 w-full">
        {columns.map((column, columnIndex) => (
          <div key={columnIndex} className="flex-1 flex flex-col gap-4">
            {column.map((image, index) => {
              const [aspectWidth, aspectHeight] = image.aspect_ratio.split(':').map(Number);
              const isLastImage = (LkView ?columnIndex === 1 : columnIndex === 3) && index === column.length - 1;
              return (
                <div
                  ref={isLastImage ? lastImageRef : null}
                  className="relative w-full"
                  style={{ aspectRatio: `${aspectWidth} / ${aspectHeight}` }}
                >
                  <GeneratedImage
                    thumbnailUrl={activeTab === "upscales" ? image.upscale_url : image.image_url}
                    image={image}
                    setSelectedImage={setSelectedImage}
                    setCompletedUpscale={setCompletedUpscale}
                    setUpscaleQueue={setUpscaleQueue}
                  />
                </div>
              );
            })}
          </div>
        ))}
      </div>
      {isFetchingNextPage && (
        <div className="w-full text-xl font-bold flex h-20 justify-center items-center mt-2 mb-10">
          <img className="h-20" src={Loader} alt="loader" />
        </div>
      )}
    </div>
  );

};

export default ImageGrid;