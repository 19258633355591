import { useParams } from 'react-router-dom';
import { useModelDetails } from '../API';
import Loader from '../assets/icons/loader.svg';

const ModelDetailView = () => {
  const { modelId } = useParams();
  const { data, isLoading, isError, error } = useModelDetails(modelId);

  if (isLoading) {
    return (
      <div className="w-full text-xl font-bold flex h-full justify-center items-center">
        <img className="h-20" src={Loader} alt="loader" />
      </div>
    );
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <div className="flex flex-col flex-1 w-full min-h-0  p-4 overflow-y-auto">
      <div className="flex p-2 text-xl "> Name: {data.data.name}</div>
      <div className="flex p-2 text-lg pb-2 "> Training Dataset Images </div>
      <div className="w-full grid grid-cols-4 gap-2 flex-wrap">
        {data.data.training_data.map(info => (
          <img
            className="w-full aspect-1 bg-gray-200 rounded-[20px]"
            key={info.image_url
            }
            src={info.image_url
            }
            alt="Training data"
          />
        ))}
      </div>
    </div>
  );
};

export default ModelDetailView;
