import React, { useEffect, useState } from 'react';
import { Box, Image, ArrowUpCircle, Video } from 'lucide-react';
import { useAuth } from '@clerk/clerk-react';
import DataTable from 'react-data-table-component';

const UsageView = () => {
    const [usageData, setUsageData] = useState({
        productsTrained: 0,
        imagesGenerated: 0,
        imagesUpscaled: 0,
        videosGenerated: 0,
    });
    const [modelData, setModelData] = useState([]); // Ensure initial state is an array
    const [search, setSearch] = useState('');
    const [timePeriod, setTimePeriod] = useState('today');
    const { getToken } = useAuth();

    const formatDate = (date) => {
        return new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric' }).format(date);
    };

    const getToday = () => formatDate(new Date());
    const getYesterday = () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return formatDate(yesterday);
    };
    const getThisWeek = () => {
        const today = new Date();
        const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1));
        return `${formatDate(firstDayOfWeek)} to ${formatDate(new Date())}`;
    };
    const getThisMonth = () => {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        return `${formatDate(firstDayOfMonth)} to ${formatDate(new Date())}`;
    };
    const getLastThreeMonths = () => {
        const today = new Date();
        const threeMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
    
        // Adjust if the day of the month is not valid in the resulting month
        if (threeMonthsAgo.getMonth() !== (today.getMonth() + 9) % 12) {
            threeMonthsAgo.setDate(0); // Set to the last day of the previous month
        }
    
        return `${formatDate(threeMonthsAgo)} to ${formatDate(today)}`;
    };

    useEffect(() => {
        const fetchUsageData = async () => {
            try {
                const token = await getToken({ template: 'token' });
                const requestBody = { time_period: timePeriod };
                console.log('Request Body:', requestBody);

                const response = await fetch(`${process.env.REACT_APP_API_URL}/usage`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestBody)
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log('API Response:', data); // Log the response to check the structure
                setUsageData({
                    productsTrained: data.trainings,
                    imagesGenerated: data.images,
                    imagesUpscaled: data.upscales,
                    videosGenerated: data.videos,
                });
                // Parse model_data string into an array
                const parsedModelData = JSON.parse(data.model_data);
                setModelData(Array.isArray(parsedModelData) ? parsedModelData : []);
            } catch (error) {
                console.error('Error fetching usage data:', error);
            }
        };

        fetchUsageData();
    }, [getToken, timePeriod]);

    const filteredData = modelData.filter(item =>
        item[1].toLowerCase().includes(search.toLowerCase())
    );

    const columns = [
        {
            name: 'Model Name',
            selector: row => row[1],
            sortable: true,
            cell: row => <div className="whitespace-normal break-words">{row[1]}</div>
        },
        {
            name: 'Date',
            selector: row => row[2],
            sortable: true,
            sortFunction: (rowA, rowB) => {
                const dateA = new Date(rowA[2].split('-').reverse().join('-'));
                const dateB = new Date(rowB[2].split('-').reverse().join('-'));
                return dateA - dateB;
            }
        },
        { name: 'Generations', selector: row => row[3], sortable: true },
        { name: 'Upscales', selector: row => row[4], sortable: true },
        { name: 'Videos', selector: row => row[5], sortable: true },
    ];

    return (
        <div className="w-3/4 mx-auto p-8">
            <div className="flex items-center justify-between mb-4">
                <h1 className="text-2xl font-bold">Usage</h1>
                <select
                    className="border border-gray-300 rounded-lg px-4 py-2 bg-white shadow-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 ease-in-out"
                    value={timePeriod}
                    onChange={(e) => setTimePeriod(e.target.value)}
                >
                    <option value="today">Today ({getToday()})</option>
                    <option value="yesterday">Yesterday ({getYesterday()})</option>
                    <option value="this_week">This Week ({getThisWeek()})</option>
                    <option value="this_month">This Month ({getThisMonth()})</option>
                    <option value="last_three_months">Last 3 Months ({getLastThreeMonths()})</option>
                </select>
            </div>
            <div className="flex justify-between mt-8">
                <div className="flex flex-col items-center justify-center w-1/5 p-4 border border-gray-300 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-200 m-2">
                    <Box className="mb-2" />
                    <span>Products Trained</span>
                    <span className="text-3xl font-bold mt-2">{usageData.productsTrained}</span>
                </div>
                <div className="flex flex-col items-center justify-center w-1/5 p-4 border border-gray-300 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-200 m-2">
                    <Image className="mb-2" />
                    <span>Images Generated</span>
                    <span className="text-3xl font-bold mt-2">{usageData.imagesGenerated}</span>
                </div>
                <div className="flex flex-col items-center justify-center w-1/5 p-4 border border-gray-300 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-200 m-2">
                    <ArrowUpCircle className="mb-2" />
                    <span>Images Upscaled</span>
                    <span className="text-3xl font-bold mt-2">{usageData.imagesUpscaled}</span>
                </div>
                <div className="flex flex-col items-center justify-center w-1/5 p-4 border border-gray-300 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-200 m-2">
                    <Video className="mb-2" />
                    <span>Videos Generated</span>
                    <span className="text-3xl font-bold mt-2">{usageData.videosGenerated}</span>
                </div>
            </div>
            <div className="mt-8">
                <h2 className="text-xl font-bold mb-4">Usage by Model</h2>
                <DataTable
                    columns={columns}
                    // data={modelData}
                    data={filteredData}
                    pagination
                    highlightOnHover
                    paginationPerPage={50} // Set default rows per page to 50
                    paginationComponentOptions={{
                        noRowsPerPage: true, // Hide the rows per page dropdown
                        rangeSeparatorText: 'of',
                        selectAllRowsItem: false,
                        selectAllRowsItemText: 'All',
                    }}
                    subHeader
                    subHeaderComponent={
                        <input
                            type="text"
                            placeholder="Search by model name"
                            className="mb-4 p-2 border border-gray-300 rounded-lg w-1/4"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    }
                />
            </div>
        </div>
    );
};

export default UsageView;