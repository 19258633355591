import React, { useState } from 'react';
import Lottie from 'react-lottie-player';
import animationData from '../../assets/icons/camera.json';
import ModelListSidebar from '../../components/Common/ModelListSidebar';
import PoseListSidebar from '../../components/Common/PoseListSidebar';
import {useStartGeneration} from '../../API/index';
import { toast } from 'react-toastify';
function EyewearModelTrainingView() {
  const [selectedModel, setSelectedModel] = useState([]);
  const [selectedPose, setSelectedPose] = useState([]);
  const [showPhotoshoot, setShowPhotoshoot] = useState(false);
  const UseBulkGeneration =useStartGeneration();
  const demoImagesForPoses = [
    'https://foosh-modal.s3.amazonaws.com/generationsv1/94a08827-b391-439c-a92e-213ae663883d/94a08827-b391-439c-a92e-213ae663883d_8798365153171252881_00002_.png',
    'https://foosh-modal.s3.amazonaws.com/generationsv1/d540eb8d-e3bc-42a4-b047-7e1cba291926/d540eb8d-e3bc-42a4-b047-7e1cba291926_3736827877968435624_00002_.png',
    'https://foosh-modal.s3.amazonaws.com/generationsv1/63dbc677-dbde-4699-86bb-59e779659548/63dbc677-dbde-4699-86bb-59e779659548_239716405052409167_00002_.png',
    'https://foosh-modal.s3.amazonaws.com/generationsv1/eafbcac1-6ccf-44cb-8ec4-2a69c9afd858/eafbcac1-6ccf-44cb-8ec4-2a69c9afd858_2232376720119615450_00002_.png',
    'https://foosh-modal.s3.amazonaws.com/generations/1aa4da25-e4a7-43ab-b1d5-624b1573f03d/1aa4da25-e4a7-43ab-b1d5-624b1573f03d_8912108668233623912_00002_.png',
  ];
  const StartGeneration=()=>{
    setShowPhotoshoot(true)
    const selectedModelIds=selectedModel?.map((model)=>model?.id);
    UseBulkGeneration.mutate(
        { selectedModelIds, selectedPose },
        {
          onSuccess: () => {
            toast.success('Bulk Generation Started!', {
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            setSelectedModel([]);
            setSelectedPose([]);
          },
          onError: () => {
            toast.error('Failed to Start bulk generation', {
              position: 'bottom-center',
            });
          },
        }
      ); 
  }
  return (
    <div className="flex flex-1 w-full overflow-hidden">
      {!showPhotoshoot ? (
        <>
          <aside className="w-[25%]">
            <ModelListSidebar
              selectedModel={selectedModel}
              setSelectedModel={setSelectedModel}
            />
          </aside>
          <aside className="w-[25%] ">
            <PoseListSidebar
              selectedPose={selectedPose}
              setSelectedPose={setSelectedPose}
            />
          </aside>

          <main
            className={`flex flex-col ${
              showPhotoshoot ? 'flex-1' : 'w-3/4'
            }  bg-white`}
          >
            {!(selectedPose?.length && selectedModel?.length ) ? (
              <div className="flex flex-col justify-center items-center flex-1 ">
                <div className="flex justify-center items-center  w-full gap-4 mb-4">
                  <img className="w-[70%]" src="/bulkStudio.png" alt=""/>
                  {/* <div className=" gap-8 flex h-full flex-col justify-evenly items-end  ">
                    <div className=" w-full aspect-1 overflow-hidden rounded-2xl">
                      <img
                        className="w-full"
                        src="https://d2h4y8xaq7mn9l.cloudfront.net/product-do-2.png"
                        alt=""
                      />
                    </div>
                    <div className=" w-full aspect-1 overflow-hidden rounded-2xl">
                      {' '}
                      <img
                        className="w-full"
                        src="https://d2h4y8xaq7mn9l.cloudfront.net/product-do-2.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="w-[42%]  flex justify-evenly ">
                    <div className="  aspect-1 rounded-3xl overflow-hidden">
                      <img
                        src="
https://foosh-modal.s3.amazonaws.com/generations/fc1bf219-b150-4f09-899b-b575bbe6ec45/fc1bf219-b150-4f09-899b-b575bbe6ec45_1509918120533152793_00002_.png"
                        alt=""
                      />
                    </div>
                  </div> */}
                </div>
                <div className="flex text-2xl font-bold mt-6 ">
                  Select your products and styles and <br /> get your photoshoot
                  done the fun way{' '}
                </div>
              </div>
            ) : (
              <div className="flex flex-col h-full overflow-hidden  flex-1">
                <div className=" p-4 mt-[2px] border-b-[1px]">
                  <div className="text-xl font-bold">
                  {selectedModel?.length}{' '}
                  products x {selectedPose?.length} shots   
                  </div>
                  <div className="text-sm">
                    NOTE : Below is an example of each shot and might differ
                    based on your selected products.
                  </div>
                </div>
                <div className=" grid mx-2 grid-cols-2 flex-1  justify-evenly gap-4 py-4 overflow-y-scroll ">
                  {demoImagesForPoses.map(image => (
                    <img
                      className="w-full rounded-3xl"
                      key={image}
                      src={image}
                      alt=""
                    />
                  ))}
                </div>
                <div className="flex justify-center  p-4 py-4  border-t-[1px] ">
                  <button
                    className="bg-black hover:bg-gray-900 text-white py-2 px-4 rounded-full shadow w-1/2 "
                    onClick={() => StartGeneration()}
                  >
                    <div className="flex flex-col items-center">
                      <span className="font-bold text-2xl">
                        Generate photoshoot
                      </span>
                      {/* <span className="text-xs">
                        Select products and photoshoot style
                      </span> */}
                    </div>
                  </button>
                </div>
              </div>
            )}
          </main>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center h-full w-full">
          <div className="mb-4">
          <Lottie
                loop={false}
                animationData={animationData}
                play
                style={{ width: 75, height: 75 }}
              />
          </div>
          <p className="text-center mb-8 text-xl">
            Your generations will be ready in ~30mins <br /> and will be
            available in your gallery
          </p>
          <button
            className="bg-black text-white py-4 px-8 rounded-full text-2xl w-1/3"
            onClick={() => setShowPhotoshoot(false)}
          >
            Start a new photoshoot
          </button>
        </div>
      )}
    </div>
  );
}

export default EyewearModelTrainingView;
