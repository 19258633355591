import React, { useState } from 'react';
import { Download } from 'lucide-react';
import Lottie from 'react-lottie-player';
import animationData from '../assets/icons/please_wait.json';
import { useBulkDownloadImages } from '../API';

const BulkDownloadButton = ({ selectedModel }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreparingModalOpen, setIsPreparingModalOpen] = useState(false);
  const [isDownloadReady, setIsDownloadReady] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState('');

  // Pass setIsDownloadReady to the hook
  const { mutate: bulkDownloadImages, isLoading } = useBulkDownloadImages(setIsDownloadReady);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsPreparingModalOpen(false);
    setIsDownloadReady(false);
    setDownloadUrl('');
  };

  const handleConfirm = () => {
    setIsModalOpen(false);
    setIsPreparingModalOpen(true);
    bulkDownloadImages(selectedModel, {
      onSuccess: (data) => {
        // Set the download URL and mark download as ready
        setDownloadUrl(data.download_url);
        setIsDownloadReady(true);
      },
      onError: () => {
        setIsPreparingModalOpen(false);
      },
    });
  };

  return (
    <>
      <div className="flex items-center cursor-pointer" onClick={handleButtonClick}>
        <Download className="w-4 h-4 mr-2" />
        <span className="whitespace-nowrap leading-none">Download Images</span>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg py-8 px-8">
            <p>
              {selectedModel.length > 0
                ? `Download all liked images for ${selectedModel.length} ${selectedModel.length === 1 ? 'product' : 'products'}?`
                : <>
                  No products selected!<br />
                  Please choose products to download images.
                </>}
            </p>
            <div className="flex justify-end mt-4 mt-6">
              <button
                className={`mr-2 px-4 py-2 rounded-lg ${selectedModel.length > 0 ? 'bg-white border border-black' : 'bg-black text-white'
                  }`}
                onClick={closeModal}
              >
                Close
              </button>
              {selectedModel.length > 0 && (
                <button
                  className="px-4 py-2 bg-black text-white rounded-lg"
                  onClick={handleConfirm}
                  disabled={isLoading}
                >
                  Confirm
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {isPreparingModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center justify-center">
            <h2 className="text-lg font-bold mb-4">
              {isDownloadReady ? 'Images ready for download' : 'Preparing images for download'}
            </h2>
            {!isDownloadReady ? (
              <Lottie
                loop
                animationData={animationData}
                play
                style={{ width: 100, height: 100 }}
              />
            ) : (
              <div className="flex justify-end">
                <a
                  href={downloadUrl}
                  className="mt-4 px-4 py-2 bg-black text-white rounded-lg flex items-center"
                  download
                  onClick={closeModal}
                >
                  <Download className="w-4 h-4 mr-2" />
                  Download Now
                </a>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BulkDownloadButton;