// src/components/LikedUpscalesView.jsx
import React from 'react';
import { useLikedUpscales } from '../../API';
import ImageGrid from './ImagesGrid';
import Loader from '../../assets/icons/loader.svg'

const LikedUpscalesView = ({ activeTab, setSelectedImage, setUpscaleQueue, setCompletedUpscale,selectedModel=[],LkView=false }) => {
  const { data, isLoading, isError, error, hasNextPage, fetchNextPage } = useLikedUpscales({activeTab: activeTab,
    limit: 6,
    isLkView: LkView,
    payload: {
      models: selectedModel,
      // other payload properties like:
      // filter: 'org',
      // any other properties you want to send in the POST request
    }
  });

  if (isLoading)
    return <div className="w-full text-xl font-bold flex h-full justify-center items-center">
      <img className="h-20" src={Loader} alt="loader" />
    </div>;

  if (isError) return <div>Error: {error.message}</div>;

  // Flatten the pages to get all images
  const images = data.pages.flatMap(page => page.images);

  return (
    <div className="flex flex-1 h-full w-full ">
      <ImageGrid
        activeTab={activeTab}
        LkView={LkView}
        images={images}
        setSelectedImage={setSelectedImage}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        setCompletedUpscale={setCompletedUpscale}
        setUpscaleQueue={setUpscaleQueue}
      />
    </div>
  );
};

export default LikedUpscalesView;
