import ModelListSidebar from '../../components/Common/ModelListSidebar';
import GalleryViewWrapper from './GalleryViewWrapper';
import { useState, useEffect } from 'react';
const MainProductionGalleryView = ({
  setUpscaleQueue,
  setCompletedUpscale,
}) => {
  const [selectedModel, setSelectedModel] = useState(() => {
    const storedModels = localStorage.getItem("bulk_generation_models_local");
    return storedModels ? JSON.parse(storedModels) : [];
  });

  useEffect(() => {
    localStorage.setItem("bulk_generation_models_local", JSON.stringify(selectedModel));
  }, [selectedModel]);
  return (
    <div className="flex flex-1 w-full overflow-hidden ">
      {/* <div className="w-[20%]">
        <ModelListSidebar
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
        />
      </div> */}
      <GalleryViewWrapper
        setUpscaleQueue={setUpscaleQueue}
        setCompletedUpscale={setCompletedUpscale}
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
      />
    </div>
  );
};
export default MainProductionGalleryView;
