import AllGenerations from './AllGenerations';
import { useState } from 'react';
import SelectedImage from '../../components/SelectedImage';
import BulkDownloadButton from '../../components/BulkDownloadButton';
import FavouriteAndUpscale from './FavouriteAndUpscale';
import ModelListSidebar from '../../components/Common/ModelListSidebar';

const GalleryViewWrapper = ({
  setUpscaleQueue,
  setCompletedUpscale,
  selectedModel,
  setSelectedModel
}) => {
  const [activeTab, setActiveTab] = useState('all');
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <div className="flex flex-1 flex-col">
      <SelectedImage
        selectedImage={selectedImage}
        onClickCancel={() => setSelectedImage(null)}
      />
      <div className="flex justify-between items-center">
        <div className="w-[25%]">
          <ModelListSidebar
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            fixedIn={true}
          />
        </div>

        <SubTopBar activeTab={activeTab} setActiveTab={setActiveTab} />
        <div className=" border-b-[1px] p-4 mt-2 border-t-[2px] border-t-[#ff000000]">
          <BulkDownloadButton selectedModel={selectedModel.map((model) => model.id)} />
        </div>
      </div>
      {selectedModel.length > 0 && (
        <div className="w-full overflow-hidden "> {/* Parent container */}
          <div className="flex flex-wrap mx-4 mb-1 ">
            {selectedModel.map((model) => (
              <div
                key={model.id}
                className="bg-gray-200 px-2 py-1 mt-1 mx-2 rounded-full  text-xs flex-shrink-0"
              >
                {model.name}
              </div>
            ))}
          </div>
        </div>
      )}
      {activeTab === 'all' ? (
        <AllGenerations
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          setUpscaleQueue={setUpscaleQueue}
          setCompletedUpscale={setCompletedUpscale}
          selectedModel={selectedModel.map((model) => model.id)}
        />
      ) : (
        <FavouriteAndUpscale
          LkView={true}
          key={`${activeTab}-${JSON.stringify(selectedModel)}`}
          activeTab={activeTab}
          setSelectedImage={setSelectedImage}
          setUpscaleQueue={setUpscaleQueue}
          setCompletedUpscale={setCompletedUpscale}
          selectedModel={selectedModel.map((model) => model.id)}
        />
      )}
    </div>
  );
};

export default GalleryViewWrapper;

const SubTopBar = ({ activeTab, setActiveTab }) => {
  return (
    <div className="flex w-full border-b-[1px] p-4 text-gray-600  border-t-[2px] border-t-[#ff000000] gap-4">
      <div
        className={`${activeTab === 'all' ? 'font-bold text-black' : 'text-gray-600'
          } cursor-pointer`}
        onClick={() => setActiveTab('all')}
      >
        All Generations
      </div>
      <div
        className={`${activeTab === 'liked' ? 'font-bold text-black' : 'text-gray-600'
          } cursor-pointer`}
        onClick={() => setActiveTab('liked')}
      >
        Favourite
      </div>
      <div
        className={`${activeTab === 'upscales' ? 'font-bold text-black' : 'text-gray-600'
          } cursor-pointer`}
        onClick={() => setActiveTab('upscales')}
      >
        Upscales
      </div>
    </div>
  );
};
