import React, { useState, useEffect, useCallback } from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';
import './ModelSelectionPopup.css';
import Loader from '../assets/icons/loader.svg';
import { useNavigate } from 'react-router-dom';

const SubHeader = ({ activeTab, setActiveTab }) => {
    const tabStyle = tab => `
      px-2 py-2 mx-1 rounded-md transition-colors duration-200 whitespace-nowrap
      ${activeTab === tab
            ? 'text-black font-semibold'
            : 'text-gray-500 hover:text-gray-700'
        }
    `;

    return (
        <div className="model-popup-header p-3 border-b-[1px] border-gray-200">
            <div className="flex justify-center items-center">
                <div className="flex justify-center flex-nowrap text-[16px] space-x-2">
                    <button
                        className={tabStyle('your_models')}
                        onClick={() => setActiveTab('your_models')}
                    >
                        Your Models
                    </button>
                    <button
                        className={tabStyle('foosh_models')}
                        onClick={() => setActiveTab('foosh_models')}
                    >
                        Foosh Models
                    </button>
                </div>
            </div>
        </div>
    );
};

const ModelSelectionPopup = ({ onSelectModels, nuxUser, initialSelectedModels = [] }) => {
    const [activeTab, setActiveTab] = useState(nuxUser ? 'foosh_models' : 'your_models');
    const [userModels, setUserModels] = useState([]);
    const [fooshModels, setFooshModels] = useState([]);
    const [loadingUserModels, setLoadingUserModels] = useState(true);
    const [loadingFooshModels, setLoadingFooshModels] = useState(true);
    const [selectedModels, setSelectedModels] = useState(initialSelectedModels);
    const [showFluxWarning, setShowFluxWarning] = useState(false);
    const navigate = useNavigate();
    const { user } = useUser();
    const { getToken } = useAuth();

    const sortModels = (models) => {
        return models.sort((a, b) => {
            if (a.name === 'Flux Ultra [Realistic shots]') return -1;
            if (b.name === 'Flux Ultra [Realistic shots]') return 1;
            return 0; // maintain original order for all other models
        });
    };

    const fetchModels = useCallback(async (isUserModels) => {
        if (!user) return;

        if (isUserModels) {
            setLoadingUserModels(true);
        } else {
            setLoadingFooshModels(true);
        }

        try {
            const token = await getToken({ template: 'token' });
            const endpoint = isUserModels ? 'models?type=trained' : 'models/public?type=all';
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/${endpoint}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            const models = data.loras || [];
            const filteredModels = models.filter(model => model.status === 'SUCCESS');
            const sortedModels = sortModels(filteredModels);

            if (isUserModels) {
                setUserModels(sortedModels);
                setLoadingUserModels(false);
            } else {
                setFooshModels(sortedModels);
                setLoadingFooshModels(false);
            }
        } catch (error) {
            console.error(`Error fetching ${isUserModels ? 'user' : 'foosh'} models:`, error);
            if (isUserModels) {
                setLoadingUserModels(false);
            } else {
                setLoadingFooshModels(false);
            }
        }
    }, [user, getToken]);

    useEffect(() => {
        if (user && userModels.length === 0) {
            fetchModels(true);
        }
    }, [user, fetchModels, userModels.length]);

    useEffect(() => {
        if (activeTab === 'foosh_models' && fooshModels.length === 0) {
            fetchModels(false);
        }
    }, [activeTab, fetchModels, fooshModels.length]);

    const handleModelSelection = (model) => {
        setSelectedModels(prev => {
            const isAlreadySelected = prev.some(m => m.id === model.id);
            const isFluxModelSelected = prev.some(m => m.name === 'Flux Ultra [Realistic shots]');

            if (model.name === 'Flux Ultra [Realistic shots]') {
                if (isAlreadySelected) {
                    return prev.filter(m => m.id !== model.id);
                } else {
                    return [model];
                }
            } else {
                if (isAlreadySelected) {
                    return prev.filter(m => m.id !== model.id);
                } else if (isFluxModelSelected) {
                    setShowFluxWarning(true);
                    setTimeout(() => setShowFluxWarning(false), 5000);
                } else if (prev.length < 2) {
                    return [...prev, model];
                }
            }
            return prev;
        });
    };

    const handleDone = () => {
        onSelectModels(selectedModels);
    };

    const displayedModels = activeTab === 'your_models' ? userModels : fooshModels;
    const showLoader = activeTab === 'your_models' ? loadingUserModels : loadingFooshModels;
    const isFluxModelSelected = selectedModels.some(m => m.name === 'Flux Ultra [Realistic shots]');

    return (
        <div className="model-popup-overlay">
            <div className="model-popup-content border-[1px] border-gray-300">
                <SubHeader activeTab={activeTab} setActiveTab={setActiveTab} />
                {
                    (activeTab === "your_models" && displayedModels.length === 0 && !showLoader)
                        ? <div className="flex flex-col p-4 justify-center items-center">
                            <div> <img src="/Group 596 (1).jpg" alt="no nux" /></div>
                            <div className="flex flex-start flex-col p-2 ">
                                <div className="text[1rem] tracking-normal">Generate images of your product, face or pet by training a custom model</div>
                                <div className="flex mt-4 cursor-pointer " onClick={() => navigate('/models')}><div className="bg-black text-white font-bold px-6 py-4 rounded-lg">ADD YOUR MODEL</div></div>
                            </div>
                        </div> :
                        <>
                            <div className="model-list">
                                {showLoader ? (
                                    <div className="w-full text-xl font-bold flex h-full justify-center items-center">
                                        <img className="h-20" src={Loader} alt="loader" />
                                    </div>
                                ) : (
                                    displayedModels.map(model => (
                                        <div
                                            key={model.id}
                                            className={`model-item px-2 cursor-pointer text-start hover:bg-[#F1F1F1] py-1 flex items-center`}
                                            onClick={() => handleModelSelection(model)}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={selectedModels.some(m => m.id === model.id)}
                                                onChange={() => { }}
                                                className="mr-2"
                                                style={{ accentColor: 'black' }}
                                            />
                                            <img src={model.thumbnailUrl} alt={model.name} className="model-thumbnail w-[42px] aspect-1 rounded-lg" />
                                            <span className="model-name text-[14px] ml-2">{model.name}</span>
                                        </div>
                                    ))
                                )}
                            </div>
                            <div className="flex justify-between items-center p-4 border-t-[1px] border-gray-200" style={{ position: 'relative', minHeight: '50px' }}>
                                <div style={{ flex: 1, textAlign: 'left' }}>
                                    {showFluxWarning ? (
                                        <span className="text-red-500 font-semibold text-[10px]">
                                        Can't mix more models with Flux Ultra [Realistic shots]
                                    </span>
                                    ) : (
                                        !isFluxModelSelected && (
                                            <span className="text-sm text-gray-500">
                                                You can mix up to 2 models
                                            </span>
                                        )
                                    )}
                                </div>
                                <button
                                    className="bg-black text-white px-4 py-2 rounded-md"
                                    onClick={handleDone}
                                >
                                    Confirm
                                </button>
                            </div>
                        </>
                }
            </div>
        </div>
    );
};

export default ModelSelectionPopup;