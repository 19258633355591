import LikedUpscalesView from '../GalleryView/LikedUpscalesView';

const FavouriteAndUpscale = ({
  selectedImage,
  activeTab,
  setSelectedImage,
  setUpscaleQueue,
  setCompletedUpscale,
  selectedModel,
}) => {

  return (
    <div className="flex flex-1 overflow-hidden">
      <div className="flex-1 overflow-y-auto">
        <LikedUpscalesView
          LkView={true}
          selectedModel={selectedModel}
          key={activeTab}
          activeTab={activeTab}
          setSelectedImage={setSelectedImage}
          setUpscaleQueue={setUpscaleQueue}
          setCompletedUpscale={setCompletedUpscale}
        />
      </div>
    </div>
  );
};

export default FavouriteAndUpscale;