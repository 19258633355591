import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PostHogProvider} from 'posthog-js/react'

const options = {
  api_host: process.env.REACT_APP_POSTHOG_HOST,
}
if ('serviceWorker' in navigator) {
  window.addEventListener('load', async function() {
    try {
      const registration = await navigator.serviceWorker.register('/service-worker.js');
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
      
      // Function to trigger batch processing
      window.triggerImageBatchProcessing = () => {
        if (navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage('process-queue');
        }
      };
    } catch (err) {
      console.log('ServiceWorker registration failed: ', err);
    }
  });
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PostHogProvider 
      apiKey={process.env.REACT_APP_POSTHOG_KEY}
      options={options}
    >
    <App />
    </PostHogProvider>
  </React.StrictMode>
);
const script = document.createElement('script');
script.src = 'https://checkout.razorpay.com/v1/checkout.js';
script.async = true;
document.body.appendChild(script);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
